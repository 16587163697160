import React, { useEffect } from 'react';
import {
  Alert,
  Box,
  Button,
  TextField as Input,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Loading from '../Loading';
import useCounter from '../../hooks/useCounter';
import isEmpty from '../../helpers';
import { makeStyles } from '@mui/styles';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling

const useStyles = makeStyles(() => ({
  // inputRoot: {
  //   borderRadius: theme.spacing(3),
  //   lineHeight: 'normal',
  //   fontSize: 32,
  //   fontWeight: 500,
  //   padding: '12px 12px',
  // },
  // labelRoot: {
  //   fontSize: 20,
  //   '&$labelFocused': {
  //     color: 'purple',
  //   },
  // },
}));
function ConfirmForm({
  values,
  errors,
  handleChange,
  isLoading,
  fieldName = 'code',
  handleSubmit,
  getNewCode,
  timerNumder = 300,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { counter, handlerRefresh } = useCounter(timerNumder);
  useEffect(() => {
    // if values.code.length === 5 submite form
    // console.log('dddd');
    if (
      !isEmpty(values[fieldName]) &&
      errors[fieldName] == null &&
      values[fieldName].length === 5
    ) {
      handleSubmit();
    }
  }, [values[fieldName]]);

  const hhMmSs = (totalSeconds) => {
    let hours = Math.floor(totalSeconds / 3600);
    hours = hours < 10 ? `0${hours}` : hours;
    totalSeconds %= 3600;
    let minutes = Math.floor(totalSeconds / 60);
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    let seconds = totalSeconds % 60;
    seconds = seconds < 10 ? `0${seconds}` : seconds;

    return `${hours}:${minutes}:${seconds}`;
  };

  return counter > 1 ? (
    <>
      <Input
        name={fieldName}
        variant="outlined"
        value={values[fieldName]}
        onChange={handleChange}
        margin="normal"
        placeholder={t('COMPONENT.CONFIRM_CODE')}
        type="tel"
        fullWidth
        required
        error={errors[fieldName] != null}
        helperText={errors[fieldName] != null && errors[fieldName]}
        InputProps={{
          maxLength: 5,
          classes: {
            root: classes.inputRoot,
          },
        }}
      />
      <Box
        mt={3}
        sx={{
          fontWeight: 500,
          width: '100%',
          textAlign: 'center',
        }}
      >
        {isLoading ? (
          <Loading />
        ) : (
          <Alert
            severity="info"
            icon={false}
            sx={{
              fontWeight: 500,
              width: '100%',
              justifyContent: 'center',
            }}
          >
            Получить новый код можно через: {hhMmSs(counter)}
          </Alert>
        )}
      </Box>
    </>
  ) : (
    <Alert
      severity="info"
      icon={false}
      sx={{
        width: '100%',
        justifyContent: 'center',
      }}
    >
      Время для ввода кода истекло. Пожалуйста, запросите новый код
      подтверждения
      <Box my={4}>
        <Button
          onClick={() => {
            getNewCode();
            handlerRefresh();
          }}
          variant="contained"
          color="primary"
        >
          {t('COMPONENT.BUTTON_NEW_CONFIRM_CODE')}
        </Button>
      </Box>
    </Alert>
  );
}

export default ConfirmForm;
