import React, { useEffect } from 'react';

import SignUpForm from '../../components/Forms/SignUpForm';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useUserStateDispatch } from '../../context/UserContext';
import { cleanPhoneValue } from '../../helpers/utils';
import dayjs from 'dayjs';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling

function SignUp({
  values,
  errors,
  handleChange,
  handleSubmit,
  setValues,
  setErrors,
  validateSignUp,
  isConfirmForm,
  getNewCode,
}) {
  const { t } = useTranslation();
  const {
    userState: { appInfo },
  } = useUserStateDispatch();

  useEffect(() => {
    if (values?.login != null) {
      const vals = {
        ...values,
        login: cleanPhoneValue(values.login),
        agreement: true,
      };
      setValues(vals);
      //setErrors(validateSignUp(vals, appInfo));
    }

    // eslint-disable-next-line
  }, []);

  const handleDateChange = (birthDate) => {
    birthDate = birthDate != null ? dayjs(birthDate) : null;
    const vals = {
      ...values,
      birthDate,
    };

    setValues(vals);
    setErrors(validateSignUp(vals, appInfo));
  };

  const handleChangeGender = (event) => {
    const vals = {
      ...values,
      gender: event.target.value,
    };
    setValues(vals);
    setErrors(validateSignUp(vals, appInfo));
  };

  return (
    <>
      {!isConfirmForm ? (
        <Typography
          variant="h5"
          style={{ textAlign: 'center', marginBottom: 24 }}
        >
          {t('SIGN.UP_TITLE')}
        </Typography>
      ) : (
        <Typography
          variant="h5"
          style={{ textAlign: 'center', marginBottom: 46 }}
        >
          {t('SIGN.UP_NOT_VALID_CONFIRM_CODE')}
        </Typography>
      )}
      <SignUpForm
        values={values}
        setValues={setValues}
        errors={errors}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        handleDateChange={handleDateChange}
        handleChangeGender={handleChangeGender}
        isConfirmForm={isConfirmForm}
        getNewCode={getNewCode}
      />
    </>
  );
}

export default SignUp;
