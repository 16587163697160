import React, { useEffect, useState } from 'react';
import {
  Menu,
  MenuItem,
  IconButton,
  Grid,
  Tooltip,
} from '@mui/material';
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';
import { useUserStateDispatch } from '../context/UserContext';

import { signOut } from '../actions/user';
import { Typography, Avatar } from '@mui/material';
import config from '../config';
//import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { useTranslation } from 'react-i18next';
import { useVisitState } from '../context/VisitContext';

const useStyles = makeStyles((theme) => ({
  headerMenuItem: {
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
  },
  headerMenuButton: {
    //padding: theme.spacing(0.5)
  },

  avatarProfile: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  headerFio: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(0),
    textAlign: 'left',
  },

  profileMenu: {
    minWidth: 265,
  },

  profileMenuItem: {
    color: theme.palette.text.secondary,
  },
  profileMenuIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.text.hint,
  },
}));

export default function User() {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    state: { visitDate, isModaleUI },
    setState,
  } = useVisitState();
  const [profileMenu, setProfileMenu] = useState(null);

  const {
    userState: {
      user: { lastName, firstName, middleName },
      isAuthenticated,
    },
    userDispatch,
  } = useUserStateDispatch();

  const fio = `${lastName} ${firstName} ${middleName ?? ''}`;
  const authToken = localStorage.getItem('authToken');
  const src = `${config.baseURLApi}user/photo/?token=${authToken}&appCode=${config.APP_CODE}`;

  useEffect(() => {
    setProfileMenu(null);
  }, [isAuthenticated]);

  return (
    <Grid container spacing={3} alignItems="center" mb={4}>
      {visitDate == null && (
        <Grid item>
          <Typography variant="h4">Запись на прием</Typography>
        </Grid>
      )}
      {visitDate == null && isAuthenticated && (
        <Grid item>
          <Tooltip
            title={<Typography variant="caption">{fio}</Typography>}
          >
            <IconButton
              aria-haspopup="true"
              color="inherit"
              className={classes.headerMenuButton}
              aria-controls="profile-menu"
              onClick={(e) => setProfileMenu(e.currentTarget)}
            >
              <Avatar
                alt={fio}
                src={src}
                classes={{ root: classes.avatarProfile }}
              />
            </IconButton>
          </Tooltip>
          <Menu
            id="profile-menu"
            open={Boolean(profileMenu)}
            anchorEl={profileMenu}
            onClose={() => setProfileMenu(null)}
            classes={{ paper: classes.profileMenu }}
            disableAutoFocusItem
          >
            <MenuItem
              className={classNames(
                classes.profileMenuItem,
                classes.headerMenuItem,
              )}
              onClick={() => signOut(userDispatch)}
            >
              <ExitToAppIcon className={classes.profileMenuIcon} />
              {t('MAINMENU.SIGNOUT')}
            </MenuItem>
          </Menu>
        </Grid>
      )}
    </Grid>
  );
}
