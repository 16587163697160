import React from 'react';
import Modale from '../../components/Modals/Modale';
//import { useVisitState } from '../../context/VisitContext';
import { useUserStateDispatch } from '../../context/UserContext';
import CreateVisitAuth from './CreateVisitAuth';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
export default function ModaleCreateVisit({ modale, toggleModale }) {
  const {
    userDispatch,
    userState: { valsTab, serverResponse },
  } = useUserStateDispatch();

  const goBackCallBack = () => {
    userDispatch({
      type: 'GO_BIGINING',
    });
  };

  return (
    <Modale
      open={modale}
      toggleModal={() => toggleModale()}
      goBackCallBack={
        valsTab.activeTabId !== 100 || serverResponse != null
          ? goBackCallBack
          : null
      }
    >
      <CreateVisitAuth toggleModal={() => toggleModale()} />
    </Modale>
  );
}
