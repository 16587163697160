import React, { Fragment, useEffect, useState } from 'react';
import {
  Alert,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  useMediaQuery,
} from '@mui/material';
//import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { TextField as Input } from '@mui/material';
import InputMask from 'react-input-mask';
import { useVisitState } from '../../context/VisitContext';
import { addVisit, customCheck } from '../../actions/visit';

import { useUserStateDispatch } from '../../context/UserContext';

import { makeStyles } from '@mui/styles';
import validate from './validationCreateVisit';
import useForm from '../../hooks/useForm';

import { useTranslation } from 'react-i18next';
import isEmpty from '../../helpers';
import config from '../../config';
import FIOandPhoneForm from '../../components/Forms/FIOandPhoneForm';

import {
  HL7ToDateToTime,
  HL7ToDateToSrtDate,
  formatDate,
} from '../../helpers/dateFormat';
import { cleanPhoneValue } from '../../helpers/utils';

import { RANGE_AGE_GROUP } from '../../helpers/validators';
import Loading from '../../components/Loading';
import ChildForm from '../../components/Forms/ChildForm';
import { ReactComponent as CheckIcon } from '../../assests/check.svg';
const useStyles = makeStyles((theme) => ({
  buttonSubmit: {
    height: theme.spacing(6),
    minWidth: theme.spacing(40),
    margin: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      height: theme.spacing(4),
      margin: 0,
    },
  },

  itemBorder: {
    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    height: 1,
    padding: '0px !important;',
    margin: 0,
  },

  textAlignRight: {
    marginLeft: 'auto',
    textAlign: 'end',
  },
  containerInfo: {
    padding: 32,
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    minWidth: 570,
    minHeight: 440,

    [theme.breakpoints.down('md')]: {
      padding: 0,
      minWidth: 'auto',
      minHeight: 'auto',
      marginTop: theme.spacing(2),
    },
  },
  avatarProfile: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  rowValue: {
    fontSize: 16,
    fontWeight: 500,
  },
}));

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
const FIOandPhoneFormRow = (props) => {
  return (
    <Grid item xs={12}>
      <FIOandPhoneForm {...props} />
    </Grid>
  );
};

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
const TitleRow = ({ item }) => {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme) =>
    theme.breakpoints.down('md'),
  );
  return (
    <>
      <Grid item xs={4}>
        <Typography variant="subtitle2">{item.title}</Typography>
      </Grid>
      <Grid item xs={isMobile ? 8 : 6.5}>
        <Typography variant="body1" className={classes.rowValue}>
          {item.value}
        </Typography>
        {isMobile && item.img != null && (
          <Avatar
            alt={item.value}
            src={item.img}
            classes={{ root: classes.avatarProfile }}
          />
        )}
      </Grid>
      {!isMobile && (
        <Grid item xs={1.5} className={classes.textAlignRight}>
          {item.img != null && (
            <Avatar
              alt={item.value}
              src={item.img}
              classes={{ root: classes.avatarProfile }}
            />
          )}
        </Grid>
      )}
      <Grid item xs={12} className={classes.itemBorder} />
    </>
  );
};

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
const AlertRow = ({ item }) => {
  return (
    <Grid item xs={12}>
      {item?.loading ? (
        <Alert severity="info">
          <Loading msg={item.value} variant="body1" isLinear />
        </Alert>
      ) : (
        <Alert severity="warning">
          <Typography variant="body1">{item.value}</Typography>
        </Alert>
      )}
    </Grid>
  );
};

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
const PhoneRow = ({
  phoneMask,
  values,
  errors,
  handlePhoneChange,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Grid item xs={4}>
        <Typography variant="subtitle2">
          {t('COMPONENT.FORM_PHONE')}
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <InputMask
          mask={phoneMask}
          value={values?.phone}
          onChange={handlePhoneChange}
          //  disabled={values?.oldEmail == null}
        >
          {() => (
            <Input
              name="phone"
              variant="standard"
              value={values?.phone}
              margin="normal"
              type="text"
              fullWidth
              required
              error={errors?.phone != null}
              helperText={errors?.phone != null && errors?.phone}
            />
          )}
        </InputMask>
      </Grid>
    </>
  );
};

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
const CustomCheckRow = ({ values, handleСustomCheck }) => {
  const { t } = useTranslation();
  return (
    <Grid item xs={12}>
      <FormControlLabel
        control={
          <Checkbox
            name="notifyCustomCheck"
            checked={values.customCheck.checked}
            onChange={handleСustomCheck}
            color="primary"
            disabled={false}
            sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
          />
        }
        label={
          <Typography variant="h6">
            {t('VISIT.AGREE_PAID_SERVICES')}
          </Typography>
        }
      />
    </Grid>
  );
};

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
const ChildFormRow = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleChange = (e, imChildRep) => {
    const vals = {
      ...props.values,
      imChildRep,
      childFirstName: null,
      childLastName: null,
      childMiddleName: null,
      childBirthDate: null,
    };
    if (!imChildRep) {
      delete vals.childFirstName;
      delete vals.childLastName;
      delete vals.childMiddleName;
      delete vals.childBirthDate;
    }

    props.setValues(vals);
    props.setErrors(props.validate(vals, props.appInfo));
  };

  return (
    <>
      <Grid item xs={12} sx={{ position: 'relative', top: -12 }}>
        <FormControlLabel
          control={
            <Checkbox
              name="imChildRep"
              checked={props.values?.imChildRep}
              onChange={handleChange}
              color="primary"
              sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
            />
          }
          label={
            <Typography variant="body1">
              {t('VISIT.IM_CHILD_REPRESENTATIVE')}
            </Typography>
          }
        />
        {props.values?.imChildRep && (
          <>
            <Typography variant="h6" mt={3}>
              {t('VISIT.ENTER_CHILD_DATA')}
            </Typography>
            <ChildForm {...props} />
            <Box mb={3}></Box>
          </>
        )}
      </Grid>
      <Grid item xs={12} className={classes.itemBorder} />
    </>
  );
};

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
export default function CreateVisit({ toggleModal }) {
  const { t } = useTranslation();
  const classes = useStyles();
  // const {
  //   visitDate,
  //   timeSlots,
  //   doctor,
  //   visitKindId,
  //   visitKindName,
  //   clinic,
  //   patientDirectionId,
  //   isOnline,
  // } = visitData;

  const {
    state: {
      doctor,
      doctor: { visitKinds, clinics },
      visitDate,
      selectedVisitKindId,
      selectedClinicId,
      isOnlineParam,
      timeSlots,
      visit,
    },
  } = useVisitState();
  const visitKindId = selectedVisitKindId;
  const visitKindName = visitKinds.find(
    (it) => it.id === selectedVisitKindId,
  )?.name;

  const clinic = clinics.find((it) => it.id === selectedClinicId);
  const isOnline = isOnlineParam ? 'Online' : 'Offline';

  const hasSlots = timeSlots != null;

  const { setState } = useVisitState();
  // const navigate = useNavigate();
  const {
    userState: { user, authToken, appInfo },
  } = useUserStateDispatch();

  const requireVisitCustomCheck =
    user.isAnonymous || !hasSlots
      ? false
      : appInfo.requireVisitCustomCheck;

  const isSettingChildRepresentativeEnabled =
    user.mmkId !== 'parent'
      ? false
      : appInfo?.isSettingChildRepresentativeEnabled &&
        (doctor?.ageGroup === 'Child' || doctor?.ageGroup === 'Any');

  const appInfoChange = {
    ...appInfo,
    isSettingChildRepresentativeEnabled,
    requireVisitCustomCheck,
  };

  const createVisit = () => {
    let comment = '';
    if (appInfoChange.showInsuranceDMS) {
      const dateFormat =
        user.lang != null && user.lang === 'ru'
          ? 'dd.mm.yyyy'
          : 'mm/dd/yyyy';
      comment = `${t('COMPONENT.FORM_DMS_COMMENT')}: ${
        user.dmsName
      } ${user.dmsNumber}
${t('COMPONENT.FORM_DMS_COMMENT_UNTILL')}: ${
        user.dmsEndDate
          ? HL7ToDateToSrtDate(user.dmsEndDate, user.lang, dateFormat)
          : ''
      }`;
    }
    if (requireVisitCustomCheck && values.customCheck.checked) {
      comment += '\n';
      comment += t('VISIT.AGREE_PAID_SERVICES');
    }
    if (values.childFirstName) {
      comment += '\n\n  ----------- ----------- ----------- \n\n';

      comment += values.childLastName;
      comment += '\n';
      comment += values.childFirstName;
      comment += '\n';
      comment += values.childMiddleName;
      comment += '\n';
      comment += formatDate(values.childBirthDate);

      comment += '\n';
    }

    addVisit({
      setState,
      doctor,
      mmkId: user.mmkId,
      visitKindId,
      visitDate,
      contactPhone: cleanPhoneValue(values.phone),
      patientDirectionId: 0,
      isOnline,
      comment,
    });

    //toggleModal();
  };

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
    setErrors,
  } = useForm(createVisit, validate, appInfoChange);

  let fioPatient = `${user.lastName} ${user.firstName} ${
    user.middleName ?? ''
  }`;
  const handlePhoneChange = (e) => {
    const vals = {
      ...values,
      phone: e.target.value,
    };
    setValues(vals);
    setErrors(validate(vals, appInfoChange));
  };

  const setCustomCheck = (val) => {
    const vals = {
      ...values,
      customCheck: { ...values.customCheck, ...val },
    };
    setValues(vals);
    setErrors(validate(vals, appInfoChange));
  };
  const handleСustomCheck = (e, value) => {
    const vals = {
      ...values,
      customCheck: {
        ...values.customCheck,
        checked: value,
      },
    };

    setValues(vals);
    setErrors(validate(vals, appInfoChange));
  };

  useEffect(() => {
    const vals = {
      mmkId: user.mmkId,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      middleName: user.middleName,
      gender: user.gender,
      phone: user.phone,
      birthDate: user.birthDate,
      needPhone: user.isAnonymous || !hasSlots,

      ageGroup: doctor?.ageGroup,
      customCheck: { status: null, checked: false, msgText: '' },
      imChildRep: false,
    };
    setValues(vals);
    setErrors(validate(vals, appInfoChange));

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (requireVisitCustomCheck && values.mmkId != null) {
      setTimeout(() => {
        customCheck({
          setCustomCheck,
          clinicId: doctor?.clinicId,
          plSubjId: doctor?.plSubjId,
          plExamId: visitKindId,
          fmClinkPatientsId: null,
          param1: user.dmsNumber,
          param2: user.dmsEndDate,
          param3: user.omsNumber,
          param4: visitDate,
          mmkId: user.mmkId,
        });
      }, 1000);
    }
  }, [values.mmkId]);

  const fioDoctor =
    doctor?.lastName != null || doctor?.firstName != null
      ? `${doctor?.lastName} ${doctor?.firstName} ${
          doctor?.middleName ?? ''
        }`
      : doctor?.plSubjName;
  const imgDoctor =
    doctor?.photoUrl != null
      ? `${config.baseURLApi}${doctor?.photoUrl}&appCode=${config.APP_CODE}`
      : null;

  const imgPatient = `${config.baseURLApi}user/photo/?token=${authToken}&appCode=${config.APP_CODE}&mmkId=${user.mmkId}`;

  const ageGroupTextValue = () => {
    if (doctor?.ageGroup === 'Adult')
      return t('VISIT.AGE_GROUP_ADULT', { age: RANGE_AGE_GROUP });
    if (doctor?.ageGroup === 'Child')
      return t('VISIT.AGE_GROUP_CHILD', { age: RANGE_AGE_GROUP });
    if (doctor?.ageGroup === 'Any') return t('VISIT.AGE_GROUP_ANY');
  };

  let gridInfo = [];
  /** ---------------------------------- items, ChildFormRow Rows -------------------------- */
  if (hasSlots) {
    gridInfo = [
      ...gridInfo,
      {
        title: t('VISIT.CR_PATIENT'),
        value: fioPatient,
        img: imgPatient,
      },
    ];
    if (appInfoChange.isSettingChildRepresentativeEnabled) {
      gridInfo = [
        ...gridInfo,
        {
          childForm: true,
        },
      ];
    }

    gridInfo = [
      ...gridInfo,

      {
        title: t('VISIT.CR_DOCTOR'),
        value: fioDoctor,
        img: imgDoctor,
      },

      {
        title: t('VISIT.CR_DATE'),
        value: `${HL7ToDateToSrtDate(visitDate)} ${HL7ToDateToTime(
          visitDate,
        )}`,
      },
      {
        title: t('VISIT.CR_ADDRESS'),
        value: clinic?.address,
      },
    ];

    if (visit.data == null) {
      gridInfo = [
        ...gridInfo,
        {
          title: t('VISIT.CR_SPEC'),
          value: doctor?.specializationInfo,
        },
        {
          title: t('VISIT.CR_VISITKIND'),
          value: visitKindName,
        },
        {
          title: t('VISIT.AGE_GROUP'),
          value: ageGroupTextValue(),
        },
      ];
    }
  }
  /** ---------------------------------- end items, ChildFormRow Rows -------------------------- */

  /** ---------------------------------- FIOandPhoneFormRow -------------------------- */
  if (!hasSlots) {
    gridInfo = [
      ...gridInfo,
      {
        fioPhoneForm: true,
      },
    ];
  }
  /** ---------------------------------- FIOandPhoneFormRow -------------------------- */
  /** ---------------------------------- age goup -------------------------- */

  // console.log(
  //   'ageGroup: ',
  //   doctor?.ageGroup,
  //   ageGroupTextValue(),
  //   'age: ',
  //   errors.ageGroup,
  // );

  if (errors.ageGroup != null) {
    gridInfo = [
      ...gridInfo,
      {
        alert: true,
        value: errors.ageGroup,
      },
    ];
  }
  /** ---------------------------------- end age goup -------------------------- */
  /** ---------------------------------- Alert custom check -------------------------- */
  if (
    hasSlots &&
    requireVisitCustomCheck &&
    values.customCheck?.status === null
  ) {
    gridInfo = [
      ...gridInfo,
      {
        alert: true,
        value: t('VISIT.WAITING_THE_CUSTOM_CHECK'),
        loading: true,
      },
    ];
  } else if (
    hasSlots &&
    requireVisitCustomCheck &&
    errors.customCheck != null
  ) {
    gridInfo = [
      ...gridInfo,
      {
        alert: true,
        value: errors.customCheck,
      },
    ];
  }
  /** ---------------------------------- end Alert custom check -------------------------- */
  /** ---------------------------------- PhoneRow -------------------------- */
  if ((user.phone == null || user.phone === '') && user.isAnonymous) {
    gridInfo = [
      ...gridInfo,
      {
        phone: true,
      },
    ];
  }
  /** ---------------------------------- end PhoneRow -------------------------- */
  /** ---------------------------------- CustomCheckRow -------------------------- */
  if (
    hasSlots &&
    values.customCheck?.status === 2 &&
    !user.isAnonymous
  ) {
    gridInfo = [
      ...gridInfo,
      {
        customCheck: true,
      },
    ];
  }
  /** ---------------------------------- end CustomCheckRow -------------------------- */

  const disableButton =
    isEmpty(visitDate) ||
    values.lastName == null ||
    values.firstName == null ||
    !isEmpty(errors);

  return (
    <>
      <Typography variant="h5" style={{ textAlign: 'center' }}>
        {visit.data == null
          ? t('MAINMENU.APPOINTMENT')
          : t('VISIT.ALERT_OK')}
      </Typography>

      {visit.data != null && (
        <Box align="center" mt={3}>
          <CheckIcon />
        </Box>
      )}

      <Grid container spacing={2} className={classes.containerInfo}>
        {!visit.isLoaded ? (
          <Grid item xs={12} align="center" my={3}>
            <Loading />
          </Grid>
        ) : visit.serverError != null ? (
          <Grid item xs={12} align="center" my={3}>
            <Alert severity="warning">
              <Typography variant="h6" component="p" mb={4}>
                {visit.serverError}
              </Typography>
            </Alert>
          </Grid>
        ) : (
          gridInfo.map((item, inx) => (
            <Fragment key={inx}>
              {item.title != null ? (
                <TitleRow item={item} />
              ) : item.fioPhoneForm && visit.data == null ? (
                <FIOandPhoneFormRow
                  values={values}
                  errors={errors}
                  handleChange={handleChange}
                  setValues={setValues}
                  setErrors={setErrors}
                  validate={validate}
                  appInfo={appInfoChange}
                />
              ) : item.alert && visit.data == null ? (
                <AlertRow item={item} />
              ) : item.childForm && visit.data == null ? (
                <ChildFormRow
                  values={values}
                  errors={errors}
                  handleChange={handleChange}
                  setValues={setValues}
                  setErrors={setErrors}
                  lang={user.lang}
                  validate={validate}
                  appInfo={appInfoChange}
                />
              ) : item.phone && visit.data == null ? (
                <PhoneRow
                  phoneMask={appInfoChange.phoneMask}
                  values={values}
                  errors={errors}
                  handlePhoneChange={handlePhoneChange}
                />
              ) : item.customCheck && visit.data == null ? (
                <CustomCheckRow
                  values={values}
                  handleСustomCheck={handleСustomCheck}
                />
              ) : null}
            </Fragment>
          ))
        )}
        <Grid
          item
          xs={12}
          align="center"
          sx={(theme) => ({
            [theme.breakpoints.down('md')]: {
              position: 'fixed',
              margin: 0,
              bottom: theme.spacing(1),

              my: 0,
            },
          })}
        >
          {visit.isLoaded &&
          visit.data == null &&
          visit.serverError == null ? (
            <Button
              className={classes.buttonSubmit}
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={disableButton}
            >
              {hasSlots
                ? t('COMPONENT.BUT_CONFIRM_APPONTMENT')
                : t('COMPONENT.BUT_SEND_EMAIL_DOCTOR')}
            </Button>
          ) : visit.isLoaded ? (
            <Button
              className={classes.buttonSubmit}
              variant="contained"
              color="secondary"
              onClick={() => {
                toggleModal();
              }}
            >
              {t('COMPONENT.BUT_CLOSE')}
            </Button>
          ) : null}
        </Grid>
      </Grid>
    </>
  );
}
