import React, { useCallback, useState } from 'react';
import { Dialog, Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import useMediaQuery from '@mui/material/useMediaQuery';
import ModaleTitle from './ModaleTitle';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  conteiner: {
    overflowY: 'auto',
    overflowX: 'hidden',
    scrollbarWidth: 'thin',
    scrollbarColor: `${theme.palette.primary.lighter} ${theme.palette.bgLight.one}`,
  },
  scrollHide: {
    overflowY: 'hidden',
  },
  dialog: {
    minHeight: 460,
    borderRadius: theme.spacing(4),
    [theme.breakpoints.down('md')]: {
      borderRadius: 0,
      margin: 0,
    },
  },
}));

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
export default function Modale({
  open,
  toggleModal,
  title,
  children,
  mx = 3,
  mt = 3,
  mb = 6,

  maxWidth = 'md',

  isScrollHide = false,
  backdrop = false,
  goBackCallBack = null,
}) {
  const classes = useStyles();

  const isMobile = useMediaQuery((theme) =>
    theme.breakpoints.down('md'),
  );
  if (isMobile) {
    mx = 1;
    mt = 1;
    mb = 8;
  }
  const [fullScreen, setFullScreen] = useState(isMobile);

  const toggleFullscreen = useCallback(() => {
    setFullScreen(!fullScreen);
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 0);
  }, [isMobile, fullScreen]);

  return (
    <Dialog
      open={open}
      onClose={toggleModal}
      maxWidth={maxWidth}
      fullScreen={fullScreen}
      classes={{
        paper: classes.dialog,
        root: backdrop && classes.backdrop,
      }}
    >
      <ModaleTitle
        title={title}
        toggleFullscreen={toggleFullscreen}
        //fullScreen={fullScreen}
        toggleModal={toggleModal}
        goBack={goBackCallBack}
      />

      <Box
        mx={mx}
        mt={mt}
        mb={mb}
        style={{
          height: fullScreen || isMobile ? '100%' : 'auto',
        }}
        className={classNames(
          classes.conteiner,
          isScrollHide && classes.scrollHide,
        )}
      >
        <Typography variant="body1">{children}</Typography>
      </Box>
    </Dialog>
  );
}
