import React, { FC } from 'react';
import { Dayjs } from 'dayjs';
import 'dayjs/locale/fr';
import 'dayjs/locale/ru';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useLanguageValue } from '../../context/LanguageContext';
import { DatePicker } from '@mui/x-date-pickers';

interface MuiUIPickerProps {
  value: Dayjs | null;
  handleChange: (value: Dayjs | null) => void;
  label: string;
  inputFormat: string;
  disablePast?: boolean;
  disabled?: boolean;
  required?: boolean;
  errorText?: string | null;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  margin?: 'normal' | 'dense' | 'none' | undefined;
  preventKeyDown?: boolean;
}

const MuiUIPicker: FC<MuiUIPickerProps> = ({
  value,
  handleChange,
  label,

  disablePast,
  disabled,
  minDate,
  maxDate,
  errorText,
  required,
  margin = 'none',
  preventKeyDown = false,
}) => {
  const {
    languageState: { language },
  } = useLanguageValue();

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={language}
    >
      <DatePicker
        disablePast={disablePast}
        disabled={disabled}
        label={label}
        value={value}
        onChange={handleChange}
        minDate={minDate}
        maxDate={maxDate}
        slotProps={{
          textField: {
            margin,
            onKeyDown: (e) => {
              if (preventKeyDown) e.preventDefault();
            },
            error: errorText != null,
            helperText: errorText != null && errorText,
            required,
            fullWidth: true,
          },
        }}
        // sx={(theme) => ({
        //   '& .MuiPickersDay-root': {
        //     '&.Mui-selected': {
        //       backgroundColor: `${theme.palette.primary.main} !important`,
        //     },
        //     '&.Mui-selected:hover': {
        //       backgroundColor: `${theme.palette.primary.light} !important`,
        //     },
        //   },
        // })}
      />
    </LocalizationProvider>
  );
};

export default MuiUIPicker;
