import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';

import DoctorCard from './DoctorCard';
import { useVisitState } from '../../context/VisitContext';
import { dateToHL7 } from '../../helpers/dateFormat';
import { getDoctor } from '../../actions/visit';
import { useUserStateDispatch } from '../../context/UserContext';
import isEmpty from '../../helpers';
import { Alert, Typography } from '@mui/material';
import AlertDialogSlide from '../../components/Modals/AlertDialogSlide';
import Modale from '../../components/Modals/Modale';

// eslint-disable-next-line react/prop-types
const ContentWiget = ({ doctor, doctorId, modale, toggleModale }) =>
  doctor.responseError != null && doctor.isLoaded ? (
    <AlertDialogSlide
      isOpen
      contentTextOk={doctor.responseError}
      severity="warning"
    />
  ) : doctorId != null && isEmpty(doctor.data) && doctor.isLoaded ? (
    <AlertDialogSlide
      isOpen
      contentTextOk={`No data by doctorId: ${doctorId}`}
      severity="warning"
    />
  ) : doctorId == null && doctor.isLoaded ? (
    <Alert severity="warning">
      <Typography variant="h6" mb={4}>
        doctorId is empty
      </Typography>
    </Alert>
  ) : (
    <DoctorCard modale={modale} toggleModale={toggleModale} />
  );

const useStyles = makeStyles((theme) => ({
  paperMob: {
    padding: 0,

    minWidth: 288,
    [theme.breakpoints.up('md')]: {
      margin: '20px 36px 0 36px ',
    },
  },
  paper: {
    padding: 0,
    width: '100%',
    minWidth: 288,
    marginTop: 20,
  },
}));

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
export default function DoctorContainer() {
  const {
    userDispatch,
    userState: { valsTab, clinics },
  } = useUserStateDispatch();
  const classes = useStyles();
  const [modale, setModale] = useState(false);
  const {
    state: {
      doctorId,
      doctor,
      visitDate,
      selectedDate,

      // selectedClinicId,
      // selectedSpecId,
      // selectedVisitKindId,
      isOnlineParam,
      filterWithTimeSlotsOnly,
      cachedTimeSlots,
      filterWithPlanningOnly,
      isModaleUI,
    },
    setState,
  } = useVisitState();

  const toggleModale = (visitDate = null) => {
    console.log('valsTab', valsTab);
    if (visitDate != null) {
      setState({
        type: 'SET_VISIT_DATE',
        payload: visitDate,
      });
    } else {
      setState({
        type: 'SET_VISIT_DATE',
        payload: null,
      });
      setState({
        type: 'RESET_VISIT',
      });
      if (isModaleUI)
        setState({
          type: 'SET_DOCTOR_ID',
          payload: null,
        });
      userDispatch({
        type: 'GO_BIGINING',
      });
    }
    if (!isModaleUI) setModale(!modale);
    if (isModaleUI && visitDate == null) setModale(!modale);
  };

  /** getting doctorId if many doctors on the page  */
  useEffect(() => {
    //console.log('isModaleUI', isModaleUI);
    if (isModaleUI) {
      const getData = (event) => {
        //console.log('event.doctorId', event.detail);
        if (event.detail?.doctorId)
          setState({
            type: 'SET_DOCTOR_ID',
            payload: event.detail?.doctorId,
          });
        setModale(true);
      };
      window.addEventListener('eventDoctorId', getData);
      return () =>
        window.removeEventListener('eventDoctorId', getData);
    }
  }, []);

  // console.log(
  //   'doctorId',
  //   doctorId,
  //   'doctor',
  //   //doctor,
  //   // 'selectedClinicId',
  //   // selectedClinicId,
  //   // 'selectedSpecId',
  //   // selectedSpecId,
  //   // 'selectedVisitKindId',
  //   // selectedVisitKindId,
  // );

  /** Getting doctor data  */
  const timeSlotsDate = dateToHL7(selectedDate);
  useEffect(() => {
    if (!isEmpty(clinics) && doctorId != null)
      getDoctor({
        dispatch: setState,
        typeAction: 'FETCH_DOCTOR',
        timeSlotsConsultationType: isOnlineParam
          ? 'Online'
          : 'Offline',
        timeSlotsDate,
        specializationId: null,
        specialization: '',
        selectedName: '',
        selectedClinicId: null,
        selectedVisitKindId: null,
        startIndex: 0,
        count: 100,
        withTimeSlotsOnly: filterWithTimeSlotsOnly,
        favoriteOnly: false,
        doctorId,
        cachedTimeSlots,
        withPlanningOnly: filterWithPlanningOnly,
        clinics,
      });
  }, [doctorId, timeSlotsDate, clinics.length]);

  const fioDoctor =
    doctor?.lastName != null || doctor?.firstName != null
      ? `${doctor?.lastName ?? ''} ${doctor?.firstName ?? ''} ${
          doctor?.middleName ?? ''
        }`
      : doctor?.plSubjName;

  const goBackCallBack = () => {
    if (visitDate == null && doctorId) return null;
    if (visitDate != null && valsTab.activeTabId === 100) {
      setState({
        type: 'SET_VISIT_DATE',
        payload: null,
      });
      setState({
        type: 'RESET_VISIT',
      });
    }

    if (valsTab.activeTabId === 1)
      userDispatch({
        type: 'GO_BIGINING',
      });
  };

  return isModaleUI ? (
    <Modale
      open={modale}
      toggleModal={() => toggleModale()}
      title={visitDate == null && fioDoctor}
      goBackCallBack={visitDate != null ? goBackCallBack : null}
    >
      <div className={classes.paperMob}>
        <ContentWiget
          doctor={doctor}
          doctorId={doctorId}
          modale={modale}
          toggleModale={toggleModale}
        />
      </div>
    </Modale>
  ) : (
    <div className={classes.paper}>
      <ContentWiget
        doctor={doctor}
        doctorId={doctorId}
        modale={modale}
        toggleModale={toggleModale}
      />
    </div>
  );
}
