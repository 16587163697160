import React, { useState } from 'react';

//import { useVisitState } from '../../context/VisitContext';
import { Alert, Button, Grid, Typography } from '@mui/material';
import Clinics from '../../components/FormComponents/CheckCliniks';
import Specs from '../../components/FormComponents/SelectSpecs';
import SelectVisitKinds from '../../components/FormComponents/SelectVisitKinds';
import DoctorCalendar from '../../components/FormComponents/DoctorCalendar';
import DoctorSlots from '../../components/FormComponents/DoctorSlots';
import ModaleCreateVisit from './ModaleCreateVisit';
import { useVisitState } from '../../context/VisitContext';
//import Loading from '../../components/Loading';
import {
  HL7ToDateToSrtDate,
  HL7ToDateToTime,
} from '../../helpers/dateFormat';
import { useTranslation } from 'react-i18next';
import User from '../../components/User';
import isEmpty from '../../helpers';
import { makeStyles } from '@mui/styles';
import CreateVisitAuth from './CreateVisitAuth';

const useStyles = makeStyles((theme) => ({
  calendarConteiner: {
    [theme.breakpoints.up('xl')]: {
      marginTop: -16,
    },
    [theme.breakpoints.down('xl')]: {
      marginTop: 10,
    },
  },
  calendarConteinerIsModaleUI: {
    [theme.breakpoints.up('md')]: {
      marginTop: -18,
    },
    [theme.breakpoints.down('md')]: {
      marginTop: 10,
    },
  },
}));

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
export default function DoctorCard({ modale, toggleModale }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    state: {
      doctorId,
      doctor: { visitKinds },
      // selectedDate,
      // selectedClinicId,
      // selectedSpecId,
      selectedVisitKindId,
      // isOnlineParam,
      // filterWithTimeSlotsOnly,
      // cachedTimeSlots,
      // filterWithPlanningOnly,
      visit,
      isModaleUI,
      visitDate,
    },
    setState,
  } = useVisitState();

  return (
    <>
      <User />
      {visit.data == null && visitDate == null ? (
        <Grid container spacing={3}>
          <Grid
            item
            sm={12}
            md={isModaleUI ? 6 : 12}
            lg={isModaleUI ? 6 : 12}
            xl={6}
          >
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <Clinics />
              </Grid>
              <Grid item xs={12}>
                <Specs />
              </Grid>
              {!isEmpty(visitKinds) && visitKinds.length > 1 && (
                <Grid item xs={12} mb={3}>
                  <SelectVisitKinds />
                </Grid>
              )}
            </Grid>
          </Grid>

          {selectedVisitKindId && (
            <Grid
              item
              sm={12}
              md={isModaleUI ? 6 : 12}
              lg={isModaleUI ? 6 : 12}
              xl={6}
              className={
                isModaleUI
                  ? classes.calendarConteinerIsModaleUI
                  : classes.calendarConteiner
              }
              mx="auto"
            >
              <DoctorCalendar />
            </Grid>
          )}

          <Grid item xs={11.6} ml={-1.5} sx={{ minHeight: 87 }}>
            <DoctorSlots toggleModale={toggleModale} />
          </Grid>
        </Grid>
      ) : visitDate == null ? (
        <Alert severity="success">
          <Typography variant="h6">{t('VISIT.ALERT_OK')}</Typography>
          <Typography variant="body2" mb={4}>
            {`${HL7ToDateToSrtDate(visitDate)} ${
              HL7ToDateToTime(visitDate) !== '00:00'
                ? HL7ToDateToTime(visitDate)
                : ''
            }`}
          </Typography>
          <Button
            variant="contained"
            onClick={() =>
              setState({
                type: 'RESET_VISIT',
              })
            }
          >
            {t('COMPONENT.BACK_ONE_STEP')}
          </Button>
        </Alert>
      ) : null}
      {!isModaleUI && visitDate != null ? (
        <ModaleCreateVisit
          modale={modale}
          toggleModale={toggleModale}
        />
      ) : isModaleUI && visitDate != null ? (
        <CreateVisitAuth toggleModal={() => toggleModale()} />
      ) : null}
    </>
  );
}
