import React from 'react';
import { makeStyles } from '@mui/styles';
import Loading from '../Loading';

//import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
//import { getDoctorsTimeSlots } from '../../actions/visit';
import { useVisitState } from '../../context/VisitContext';
import { HL7ToDateToTime } from '../../helpers/dateFormat';
const useStyles = makeStyles((theme) => ({
  marginBottom: { marginBottom: theme.spacing(2) },
  buttonSlot: {
    margin: `0 ${theme.spacing(2)} ${theme.spacing(2)} 0`,
    minWidth: theme.spacing(9),
    padding: `${theme.spacing(1.2)} ${theme.spacing(1.8)}`,
  },
}));
import isEmpty from '../../helpers';
import { Alert, Box, IconButton, useMediaQuery } from '@mui/material';
import { Carousel } from '@trendyol-js/react-carousel';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import useResizeObserver from 'use-resize-observer';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
const DoctorSlots = ({ toggleModale }) => {
  const classes = useStyles();
  const {
    state: { selectedVisitKindId, doctor, timeSlots, isModaleUI },
  } = useVisitState();
  const { ref, width } = useResizeObserver();
  const br = useMediaQuery((theme) =>
    theme.breakpoints.down(!isModaleUI ? 'xl' : 'md'),
  );
  const show = width ? Math.ceil(width / 100) : br ? 4 : 7;
  //console.log('br', br, 'show', show);

  const Item = ({ slot }) => {
    return (
      <Button
        color="primary"
        variant="outlined"
        value={slot}
        onClick={(e) => {
          toggleModale(e.currentTarget.value);
        }}
        key={slot}
        className={classes.buttonSlot}
      >
        {HL7ToDateToTime(slot)}
      </Button>
    );
  };

  return (
    <>
      {!doctor.isLoaded ? (
        <Loading />
      ) : doctor.responseError != null ? (
        <Alert severity="warning">
          <Typography variant="h6" component="p">
            {doctor.responseError}
          </Typography>
        </Alert>
      ) : !isEmpty(timeSlots) && selectedVisitKindId != null ? (
        <Box ref={ref}>
          <Carousel
            show={show}
            slide={show - 1}
            responsive={true}
            swiping={true}
            rightArrow={
              <IconButton color="primary" aria-label="next slot">
                <KeyboardArrowRightIcon />
              </IconButton>
            }
            leftArrow={
              <IconButton color="primary" aria-label="previos slot">
                <KeyboardArrowLeftIcon />
              </IconButton>
            }
          >
            {timeSlots.map((slot) => (
              <Item slot={slot} key={slot} />
            ))}
          </Carousel>
        </Box>
      ) : (
        <Alert severity="info">
          Слоты не найдены, попробуйте выбрать другие опции для
          получения слотов
        </Alert>
      )}
    </>
  );
};

export default DoctorSlots;
