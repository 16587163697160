import CssBaseline from './CssBaseline';
import Input from './Input';
import Button from './Button';
import Tooltip from './Tooltip';
import Typography from './Typography';
import IconButton from './IconButton';
import Picker from './Picker';
import Menu from './Menu';
import Alert from './Alert';
import Select from './Select';
import Paper from './Paper';
// ----------------------------------------------------------------------

import { Theme } from '@mui/material/styles';

export default function ComponentsOverrides(theme: Theme) {
  return Object.assign(
    CssBaseline(),
    Input(theme),
    Button(theme),
    Tooltip(theme),
    Typography(theme),
    IconButton(theme),
    Picker(theme),
    Menu(theme),
    Alert(theme),
    Select(theme),
    Paper(theme),
  );
}
