// ----------------------------------------------------------------------

import { Theme } from '@mui/material/styles';

export default function CssBaseline() {
  return {
    MuiCssBaseline: {
      styleOverrides: {
        '.doctor-card__visit-widget': {
          minWidth: 720,
        },

        '@media (max-width:1536px)': {
          '.doctor-card__visit-widget': {
            margin: '8px 0 0 6.8%',
            minWidth: 300,
          },
        },

        '@media (max-width:767px)': {
          '.doctor-card__visit-widget': {
            margin: '8px 0 0',
          },
        },
      },
    },
  };
}
