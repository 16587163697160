import React, { useCallback, useEffect, useMemo } from 'react';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  DateCalendar,
  DayCalendarSkeleton,
  PickersDay,
} from '@mui/x-date-pickers';
import dayjs from 'dayjs';

import { useVisitState } from '../../context/VisitContext';
import { doctorFreeDates } from '../../actions/visit';

//import { useTranslation } from 'react-i18next';

import { dateToHL7 } from '../../helpers/dateFormat';

import { withStyles } from '@mui/styles';
import { Badge } from '@mui/material';
import { useLanguageValue } from '../../context/LanguageContext';

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.light,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    top: 32,
    left: 20,
    width: 5,
    height: 5,
    minWidth: 5,

    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  //'&.MuiBadge-dot': {},
}))(Badge);

function ServerDay(props) {
  const {
    highlightedDays = [],
    day,
    outsideCurrentMonth,
    ...other
  } = props;

  const isSelected =
    !props.outsideCurrentMonth &&
    highlightedDays.indexOf(dateToHL7(props.day.toDate())) >= 0;

  return isSelected ? (
    <StyledBadge
      key={props.day.toString()}
      overlap="circular"
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      variant="dot"
    >
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
      />
    </StyledBadge>
  ) : (
    <PickersDay
      {...other}
      outsideCurrentMonth={outsideCurrentMonth}
      day={day}
      disabled
    />
  );
}

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
const DoctorCalendar = () => {
  //const { t } = useTranslation();
  const requestAbortController = React.useRef(null);
  const {
    setState,
    state: {
      selectedDate,
      freeDates,
      doctorId,
      selectedClinicId,
      selectedVisitKindId,
      doctor: { plSubjId },
      cachedTimeSlots,
      isOnlineParam,
    },
  } = useVisitState();

  const isOnline = isOnlineParam ? 'Online' : 'Offline';

  const dateNow = dayjs(new Date().setHours(0, 0, 0, 0));

  const selectedDateHl7 = dateToHL7(selectedDate);

  const shouldDisableDate = (date) => {
    return date.diff(dateNow) > 0;
  };

  useEffect(() => {
    const visitDatesStart = dateToHL7(dateNow.toDate());
    const visitDatesEnd = dateToHL7(
      dayjs(selectedDate.toDate()).endOf('month').toDate(),
    );
    if (requestAbortController.current) {
      // make sure that you are aborting useless requests
      // because it is possible to switch between months pretty quickly
      requestAbortController.current.abort();
    }
    doctorFreeDates(
      setState,
      selectedDate,
      selectedClinicId,
      doctorId,
      selectedVisitKindId,
      visitDatesStart,
      visitDatesEnd,
      plSubjId,
      isOnline,
      cachedTimeSlots,
    );
    return () => {
      // Cleanup function to abort any pending requests
      if (requestAbortController.current) {
        requestAbortController.current.abort();
      }
    };
    // eslint-disable-next-line
  }, [selectedDateHl7, selectedClinicId, selectedVisitKindId]);

  const {
    languageState: { language },
  } = useLanguageValue();

  const handleChange = useCallback((startDate) => {
    const selectDate = shouldDisableDate(startDate)
      ? startDate
      : dateNow;
    setState({
      type: 'SET_SELECT_DATE',
      payload: selectDate,
    });
  }, []);

  return useMemo(
    () =>
      selectedVisitKindId && (
        <LocalizationProvider
          dateAdapter={AdapterDayjs}
          adapterLocale={language}
        >
          <DateCalendar
            //id="date-picker-inline"
            disablePast
            value={dayjs(selectedDate)}
            onChange={handleChange}
            onMonthChange={handleChange}
            renderLoading={() => <DayCalendarSkeleton />}
            loading={!freeDates.isLoaded}
            slots={{
              day: ServerDay,
            }}
            slotProps={{
              day: {
                highlightedDays: freeDates.data,
              },
            }}
            sx={(theme) => ({
              '& .MuiPickersDay-root': {
                '&.Mui-selected': {
                  backgroundColor: `${theme.palette.primary.main} !important`,
                },
                '&.Mui-selected:hover': {
                  backgroundColor: `${theme.palette.primary.light} !important`,
                },
              },
            })}
            maxDate={dayjs().add(30, 'day')}
          />
        </LocalizationProvider>
      ),
    [freeDates.isLoaded, selectedVisitKindId],
  );
};

export default DoctorCalendar;
