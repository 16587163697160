import { Theme } from '@mui/material/styles';

export default function Picker(theme: Theme) {
  return {
    MuiPickerStaticWrapper: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          width: 'auto',
          margin: 'auto',
          // borderRadius: theme.spacing(0.8),
          // border: `1px solid ${theme.palette.primary.main}`,

          [theme.breakpoints.down('xl')]: {
            width: 290,
          },
        },
        content: {
          minWidth: 288,
          margin: 0,
          backgroundColor: 'transparent',
        },
      },
    },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          width: 'auto',
          lineHeight: 'normal',
          fontSize: 14,
          [theme.breakpoints.down('xl')]: {
            width: 290,
          },
          '& .MuiDayPicker-weekDayLabel': {
            lineHeight: 'normal',
            fontSize: 14,
          },
          '& .MuiPickersDay-root': {
            lineHeight: 'normal',
            fontSize: 14,
            backgroundColor: theme.palette.primary.light,
            '&:hover': {
              color: '#fff',
              background: theme.palette.primary.darker,
            },
          },
          '& .MuiPickersCalendarHeader-root ': {
            [theme.breakpoints.down('xl')]: {
              paddingLeft: 17,
              paddingRight: 2,
            },
          },
          margin: 0,
        },
      },
    },
  };
}
