import React from 'react';
import CreateVisit from './CreateVisit';
import { useUserStateDispatch } from '../../context/UserContext';
import Sign from './Sign';
import AnonymousNotEnabled from './AnonymousNotEnabled';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
export default function CreateVisitAuth({ toggleModal }) {
  const {
    userState: {
      isAuthenticated,
      user: { isAnonymous },
      appInfo,
    },
  } = useUserStateDispatch();

  const anonymousNotEnabled =
    isAnonymous && !appInfo?.isAnonymousVisitsEnabled;

  return isAuthenticated ? (
    anonymousNotEnabled ? (
      <AnonymousNotEnabled />
    ) : (
      <CreateVisit toggleModal={toggleModal} />
    )
  ) : (
    <Sign toggleModal={toggleModal} />
  );
}
