import React, { useEffect } from 'react';
import SignIn from './SignIn';
import SignUp from './SignUp';
import SignInConfirm from './SignInConfirm';
import {
  createUser,
  singInUpConfirmationCode,
  loginUser,
  otpLogin,
  reqNewAccConfCode,
} from '../../actions/user';
import useForm from '../../hooks/useForm';
import { validate as validateSignUp } from '../../pages/sign_in_up/validationSignUp';
import validateConfirmSignUp from '../../pages/sign_in_up/validationConfirmSignUp';
import validateSignIn from '../../pages/sign_in_up/validationSignIn';
import validateOtpLogin from './validationOtpLogin';
import validateSignInCode from '../../pages/sign_in_up/validationSignInCode';
import { cleanPhoneValue } from '../../helpers/utils';

import { dateToHL7 } from '../../helpers/dateFormat';

import { useUserStateDispatch } from '../../context/UserContext';
// import { sendRequestEmailToClinic } from '../../actions/visit';
// import { useVisitState } from '../../context/VisitContext';
import ChangePassword from '../sign_in_up/ChangePassword';
import { makeStyles } from '@mui/styles';
import { useLanguageValue } from '../../context/LanguageContext';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import OptLogin from './OtpLogin';

const useStyles = makeStyles((theme) => ({
  container: {
    minMidth: 420,
    [theme.breakpoints.down('md')]: {
      minMidth: 'auto',
    },
  },
}));

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
function Sign() {
  const classes = useStyles();

  const {
    languageState: { language },
  } = useLanguageValue();
  const {
    userDispatch,
    userState: { appInfo, valsTab, serverResponse },
  } = useUserStateDispatch();

  const { t } = useTranslation();

  const quickLogin = () => {
    otpLogin({
      userDispatch,
      language,
      login: cleanPhoneValue(values.login),
      code: values.code === null ? undefined : values.code,
    });
  };

  const login = () =>
    loginUser(
      userDispatch,
      values?.login,
      values?.password,

      language,
    );

  const signUp = () =>
    createUser(
      userDispatch,
      {
        ...values,
        birthDate:
          values.birthDate != null && dateToHL7(values.birthDate),
      },
      appInfo.countryCode,
    );

  const confirmSignUp = () => {
    const codeObj = values.code
      ? { confirmationCode: values.code }
      : { confirmationCode: values.confirmationCode };
    singInUpConfirmationCode(
      userDispatch,
      {
        ...codeObj,
        login: values.login,
        birthDate:
          values.birthDate != null && dateToHL7(values.birthDate),
      },
      language,
    );
  };
  const getNewCodeOptLogin = () => {
    userDispatch({
      type: 'RESET_RESPONSE',
    });
    const vals = { login: cleanPhoneValue(values.login) };
    setValues(vals);
    setErrors(validate(vals, appInfo));

    otpLogin({
      userDispatch,
      language,
      login: cleanPhoneValue(vals.login),
    });
  };
  const getNewCode = (isAccNotConfirm = false) => {
    userDispatch({
      type: 'RESET_RESPONSE',
    });
    const vals = { login: cleanPhoneValue(values.login) };
    setValues(vals);
    setErrors(validate(vals, appInfo));

    reqNewAccConfCode({
      userDispatch,
      login: cleanPhoneValue(vals.login),
      isAccNotConfirm,
    });
  };

  const goBack = () => {
    const vals = { login: cleanPhoneValue(values.login) };
    setValues(vals);
    setErrors(validate(vals, appInfo));
    userDispatch({
      type: 'GO_BIGINING',
    });
  };

  useEffect(() => {
    console.log(
      'valsTab.activeTabId',
      valsTab.activeTabId,
      'serverResponse?.action',
      serverResponse?.action,
    );

    // if (
    //   /* if start */
    //   serverResponse == null
    // ) {
    //   userDispatch({
    //     type: 'GO_BIGINING',
    //   });
    // }

    if (
      /* if catch this response switch SignUpForm */
      serverResponse?.action === 'WRONG_LOGIN_OR_CONFIRMATION_CODE'
    ) {
      userDispatch({
        type: 'SET_ACTIVE_TAB',
        payload: 1,
      });
    }
    if (
      /* if code expired */
      serverResponse?.action === 'CONFIRMATION_CODE_EXPIRED' ||
      serverResponse?.action === 'NUMBER_OF_ATTEMPTS_EXCEEDED'
    ) {
      const tout = setTimeout(() => {
        goBack();
      }, 3500);
      return () => clearTimeout(tout);
    }

    if (
      (serverResponse?.action == null ||
        serverResponse?.action === 'OK') &&
      valsTab.activeTabId === 100
    ) {
      userDispatch({
        type: 'GO_BIGINING',
      });
    }
    // if (serverResponse?.action === 'ACCOUNT_IS_NOT_CONFIRMED') {
    //   const tout = setTimeout(() => {
    //     getNewCode();
    //   }, 3500);
    //   return () => clearTimeout(tout);
    // }
    // eslint-disable-next-line
  }, [serverResponse?.action]);

  const isConfirmForm =
    serverResponse?.action === 'CONFIRM_CODE_SENT' ||
    serverResponse?.action === 'WRONG_CONFIRMATION_CODE' ||
    serverResponse?.action === 'DO_CONFIRM_NEW_ACCOUT';

  let submit,
    validate = null;
  if (valsTab.activeTabId === 100) {
    submit =
      serverResponse?.action === 'DO_CONFIRM_NEW_ACCOUT' ||
      serverResponse?.action === 'ACCOUNT_IS_NOT_CONFIRMED'
        ? confirmSignUp
        : quickLogin;
    validate = isConfirmForm ? validateSignInCode : validateOtpLogin;
  }
  if (valsTab.activeTabId === 0) {
    submit = login;
    validate = validateSignIn;
  }
  if (valsTab.activeTabId === 1) {
    submit = isConfirmForm ? confirmSignUp : signUp;
    validate = isConfirmForm ? validateConfirmSignUp : validateSignUp;
  }

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
    setErrors,
  } = useForm(submit, validate, appInfo);

  return (
    <Box mx={4} className={classes.container}>
      {valsTab.activeTabId === 100 ? (
        <OptLogin
          values={values}
          errors={errors}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          setValues={setValues}
          setErrors={setErrors}
          validate={validate}
          isConfirmForm={isConfirmForm}
          getNewCode={
            serverResponse?.action === 'DO_CONFIRM_NEW_ACCOUT' ||
            serverResponse?.action === 'ACCOUNT_IS_NOT_CONFIRMED'
              ? getNewCode
              : getNewCodeOptLogin
          }
        />
      ) : valsTab.activeTabId === 0 ? (
        valsTab?.warningNote === 'CONFIRM_AUTH' ? (
          <>
            <Typography
              variant="h5"
              sx={{ textAlign: 'center', marginBottom: 3 }}
            >
              {t('SIGN.CH_CONFIRM_CODE')}
            </Typography>
            <SignInConfirm />
          </>
        ) : (
          <SignIn
            values={values}
            errors={errors}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
          />
        )
      ) : valsTab.activeTabId === 1 ? (
        <SignUp
          values={values}
          errors={errors}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          setValues={setValues}
          setErrors={setErrors}
          validateSignUp={validateSignUp}
          isConfirmForm={isConfirmForm}
          getNewCode={getNewCode}
        />
      ) : valsTab.activeTabId === 2 ? (
        <>
          <Typography
            variant="h5"
            sx={{ textAlign: 'center', marginBottom: 3 }}
          >
            {t('SIGN.CH_PASS_BUTTON')}
          </Typography>
          <ChangePassword />
          <Button
            color="primary"
            variant="text"
            onClick={() =>
              userDispatch({
                type: 'SET_ACTIVE_TAB',
                payload: 0,
              })
            }
            style={{
              width: '100%',
              marginTop: 24,
              marginBottom: 24,
            }}
          >
            {t('COMPONENT.BACK_ONE_STEP')}
          </Button>
        </>
      ) : null}
    </Box>
  );
}

export default Sign;
