import React, { useReducer, useContext, createContext } from 'react';
import { savedEntites } from '../actions/user';
import config from '../config';
const UserContext = createContext();

function userReducer(state, action) {
  //console.log('action', action);
  switch (action.type) {
    case 'LOGIN_PATIENT':
      return { ...state, ...action.payload, isAuthenticated: true };

    case 'SIGN_OUT_SUCCESS':
      console.log(' ==== SIGN_OUT_SUCCESS === ');
      return {
        ...state,
        isAuthenticated: false,
        serverResponse: null,
        user: {},
      };

    case 'SET_USER':
      return {
        ...state,
        ...action.payload,
      };
    case 'SET_PROFILE_CODE_WRONG':
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload.user,
        },
        serverResponse: action.payload.serverResponse,
        isLoaded: true,
      };

    case 'SET_LOADING':
      return {
        ...state,
        isLoaded: false,
      };
    case 'SET_ACTIVE_TAB':
      return {
        ...state,
        serverResponse: null,
        isLoaded: true,
        valsTab: {
          activeTabIdPrev: state.valsTab.activeTabId,
          activeTabId: action.payload,
        },
      };
    case 'RESET_RESPONSE':
      return {
        ...state,
        isLoaded: true,
        serverResponse: null,
      };
    case 'GO_BIGINING':
      return {
        ...state,
        isLoaded: true,
        serverResponse: null,
        valsTab: {
          activeTabIdPrev: null,
          activeTabId: 100,
        },
      };

    case 'SET_SERVER_RESPONSE':
      return {
        ...state,
        isLoaded: true,
        serverResponse: action.payload,
      };

    case 'LOGIN_FAILURE':
      return {
        ...state,
        isLoaded: true,
        serverResponse: { message: 'Something went wrong' },
      };

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
function UserProvider({ children }) {
  const authToken = localStorage.getItem('authToken');
  const clinics = JSON.parse(localStorage.getItem('clinics')) ?? [];

  const user = JSON.parse(localStorage.getItem('user')) ?? {
    lang: config.defLang,
    clinicId: 0,
    mmkId: null,
  };

  const isAuthenticated = authToken != null && user?.mmkId != null;

  const [userState, userDispatch] = useReducer(userReducer, {
    /** user  */
    isAuthenticated,
    user,

    ...savedEntites.reduce((acc, cur) => {
      acc[cur] = JSON.parse(localStorage.getItem(cur));
      return acc;
    }, {}),

    isLoaded: true,
    serverResponse: null,
    valsTab: {
      activeTabIdPrev: null,
      activeTabId: 100,
    },
    clinics,
    authToken,
  });

  return (
    <UserContext.Provider value={{ userState, userDispatch }}>
      {children}
    </UserContext.Provider>
  );
}

function useUserStateDispatch() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error(
      'useUserStateDispatch must be used within a UserProvider',
    );
  }
  return context;
}

export { UserProvider, useUserStateDispatch };
