import React, { useEffect } from 'react';

import { getDictsData, afterReloadPage } from '../actions/user';

// context
import { useUserStateDispatch } from '../context/UserContext';
import { useLanguageValue } from '../context/LanguageContext';

import {
  Alert,
  Dialog,
  DialogContent,
  Typography,
} from '@mui/material';

// ----------------------------------------------------------------------

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling

const ModalError = ({ serverResponseDictsError }) => {
  return (
    <Dialog open={true} keepMounted>
      <DialogContent>
        <Alert severity="error">
          <Typography variant="h5">
            {serverResponseDictsError}
          </Typography>
        </Alert>
      </DialogContent>
    </Dialog>
  );
};

export default function InitApp() {
  const { userState, userDispatch } = useUserStateDispatch();
  const { isAuthenticated, user, serverResponseDictsError } =
    userState;
  const {
    languageState: { language },
  } = useLanguageValue();

  const lang = user?.lang || language;

  useEffect(() => {
    getDictsData(userDispatch);
    afterReloadPage(isAuthenticated, lang, userDispatch);
  }, []);

  return serverResponseDictsError ? (
    <ModalError serverResponseDictsError={serverResponseDictsError} />
  ) : (
    <React.Fragment />
  );
}
