import React from 'react';

import { useTranslation } from 'react-i18next';
import { useVisitState } from '../../context/VisitContext';
import isEmpty from '../../helpers';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';
import FormLabel from '@mui/material/FormLabel';

/* eslint-disable react/prop-types */ // TODO: upgrade to latest eslint tooling
export default function Clinics() {
  const { t } = useTranslation();
  const {
    state: {
      doctor: { clinics, isLoaded },
      selectedClinicId,
    },
    setState,
  } = useVisitState();

  const handleChange = (e) => {
    setState({
      type: 'SET_CLINIC',
      payload: parseInt(e.target.value, 10),
    });
  };
  const value = (clinics || []).find(
    (item) => item?.id === parseInt(selectedClinicId, 10),
  )?.id;

  return (
    !isEmpty(clinics) && (
      <FormControl>
        <FormLabel
          id="clinics-label"
          sx={(theme) => ({
            mb: 2,
            color: theme.palette.text.secondary,
          })}
        >
          {t(`COMPONENT.CLINICS`)}
        </FormLabel>
        <RadioGroup
          aria-labelledby="clinics-label"
          name="clinics"
          value={value}
          onChange={handleChange}
          disabled={!isLoaded}
        >
          {clinics.map((item) => (
            <FormControlLabel
              key={item.id}
              value={item.id}
              control={
                <Radio
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 28,
                    },
                  }}
                />
              }
              sx={{ mb: 2 }}
              label={
                <Typography variant="body1">
                  {item.name}
                  <Typography variant="body2">
                    {item.address}
                  </Typography>
                </Typography>
              }
            />
          ))}
        </RadioGroup>
      </FormControl>
    )
  );
}
